<template>
<div class="fontsize">
    <b-row style="padding: 4%;">
        <b-col sm="5" class="coinInfo">
            <div class="text-center" v-if="isLoadData" style="margin-top:50%">
                <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
            </div>
            <div v-else-if="paymentChannel != 'truemoney'&& isLoadData == false">
                <span style="fontWeight:bold">เลือกจำนวนทองที่ต้องการเติม</span>
                <b-row v-for="(list, index) in coinData" :key="index" style="marginTop:10px">
                    <b-col>
                        <img src="./../../../assets/price.png" width="18px" />
                        <span style="fontSize:14px"> {{list.coin}}</span>
                    </b-col>
                    <b-col>
                        <b-button size="sm" class="cashBtn" :id="'cashBtn'+index" :pressed.sync="list.state" @click="selectCoin(index)">{{list.cash}} บาท</b-button>
                    </b-col>
                </b-row>
            </div>
            <div v-else>
                <div style="fontWeight:bold;marginBottom:2px">อัตราแลกเปลี่ยนจำนวนทอง</div>
                <div>
                    <b-row class="coinInfoTable">
                        <b-col>
                            <span style="fontSize:14px">จำนวนทอง</span>
                        </b-col>
                        <a-divider type="vertical" style="color:#8F8F8F;height:auto" />
                        <b-col>
                            <span style="fontSize:14px">ราคา</span>
                        </b-col>
                    </b-row>
                    <b-row class="coinInfoTable" v-for="(list, index) in coinData" :key="index" style="marginTop:-1px">
                        <b-col>
                            <img src="./../../../assets/price.png" width="18px" />
                            <span style="fontSize:14px"> {{list.coin}}</span>
                        </b-col>
                        <a-divider type="vertical" style="color:#8F8F8F;height:auto" />
                        <b-col>
                            <span style="fontSize:14px"> {{list.cash}}</span>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-col>
        <a-divider type="vertical" style="color:#8F8F8F;height:auto;width:1px;padding:0;" />
        <b-col sm="6" class="paymentDetails">
            <span><b>รายละเอียดการชำระเงิน</b></span>
            <div>
                <table style="width:100%">
                    <tr>
                        <td class="paymentDetail">ผู้ใช้</td>
                        <td class="paymentDetail">: {{userInfo.displayname}}</td>
                    </tr>
                    <tr v-if="paymentChannel != 'truemoney'">
                        <td class="paymentDetail">จำนวนทองที่ได้รับ</td>
                        <td class="paymentDetail">: <img src="./../../../assets/price.png" width="18px" /> {{coinSelected | numFormat}}</td>
                    </tr>
                    <tr v-if="paymentChannel != 'truemoney'">
                        <td class="paymentDetail">ราคา</td>
                        <td class="paymentDetail">: {{cashSelected}} บาท</td>
                    </tr>
                    <tr v-if="paymentChannel == 'truemoney'">
                        <td class="paymentDetail">อีเมล์</td>
                        <td class="paymentDetail">: nattanat@gmail.com</td>
                    </tr>
                </table>
            </div>
            <div class="myCoin">
                <table style="width:100%">
                    <tr>
                        <td>ทองทั้งหมด</td>
                        <td><img src="./../../../assets/price.png" width="18px" /> <span>{{totalcoin | numFormat}}</span></td>
                    </tr>
                </table>
            </div>
            <div class="refund">** Refund: จะคืนเงินในกรณีที่ชำระเงินไม่เข้าในระบบเท่านั้น</div>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from 'axios'
import {
    mapActions,
    mapGetters
} from 'vuex'
export default {
    components: {},
    data() {
        return {
            isLoadData: false,
            coinData: [],
            coinSelected: 0,
            cashSelected: 0,
            totalcoin: 0
        }
    },
    computed: {
        ...mapGetters({
            paymentChannel: 'GET_PAYMENT_CHANNEL',
            userInfo: 'GET_USER_INFO'
        })
    },
    mounted() {
        this.getCoinData(this.paymentChannel);
    },
    watch: {
        paymentChannel: function () {
            this.getCoinData(this.paymentChannel);
        }
    },
    methods: {
        ...mapActions(['SET_CASH']),
        async getCoinData(channel) {
            this.isLoadData = true
            var url = ""
            if (channel == "credit") url = 'https://api2.novelrealm.com/module/payment&action=payment_credit'
            else if (channel == "bank") url = 'https://api2.novelrealm.com/module/payment&action=payment_bank'
            else if (channel == "linepay") url = 'https://api2.novelrealm.com/module/payment&action=payment_linepay'
            else if (channel == "truewallet") url = 'https://api2.novelrealm.com/module/payment&action=payment_truewallet'
            else if (channel == "qrcode") url = 'https://api2.novelrealm.com/module/payment&action=payment_qrcode'
            else if (channel == "shopee") url = 'https://api2.novelrealm.com/module/payment&action=payment_shopee'
            const {
                data
            } = await axios.get(url)
            this.coinData = []
            for (var n = 0; n < Object.entries(data.rate).length; n++) {
                this.coinData.push({
                    coin: Object.values(data.rate)[n],
                    cash: Object.keys(data.rate)[n]
                })
            }
            this.isLoadData = false
            setTimeout(() => {
                document.getElementById("cashBtn0").style = 'background-color:#849EB4;'
                this.changeInfo(0)
                this.selectCoin(0)
            }, 10);
        },
        selectCoin(index) {
            for (var n = 0; n < this.coinData.length; n++) {
                document.getElementById("cashBtn" + n).style = 'background-color:#BACEDE;box-shadow: none;'
            }
            document.getElementById("cashBtn" + index).style = 'background-color:#849EB4;box-shadow: none;'
            this.changeInfo(index)
        },
        changeInfo(index) {
            this.coinSelected = this.coinData[index].coin
            this.totalcoin = parseInt(this.coinData[index].coin) + parseInt(this.userInfo.member_gold)
            this.cashSelected = this.coinData[index].cash
            this.SET_CASH(this.cashSelected)
        }
    }
}
</script>

<style scoped>
.cashBtn {
    font-size: 13px;
    background-color: #BACEDE;
    color: white;
    border: none;
    width: 99px
}

.cashBtn:focus,
.cashBtn:hover {
    background-color: #849EB4;
    box-shadow: none;
}

.coinInfoTable {
    border: 1px solid #C4C4C4;
    padding: 4px;
    text-align: center;
}

.coinInfo {
    margin-right: 20px;
}

.paymentDetail {
    padding: 25px 0px 0px 0px;
}

.myCoin {
    padding: 5px;
    border: 1px solid #EE806B;
    border-radius: 5px;
    text-align: center;
    width: 85%;
    margin: 15% auto 0px auto;
}

.refund {
    color: #ADADAD;
    font-size: 13.5px;
    margin-top: 15%;
}

@media screen and (max-width: 500px) {

    .coinInfo {
        margin-right: 0px;
    }

    .paymentDetails {
        margin-top: 10%;
    }

    .fontsize {
        font-size: 14px;
    }
}
</style>
