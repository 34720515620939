<template>
<div class="scope">
    <div v-if="paymentChannel == 'credit'">
        <div>
            <b>Credit/Debit</b>
        </div>
        <img src="./../../../assets/mastercard.png" width="50px" class="creditImg" />
        <img src="./../../../assets/visa.png" width="50px" class="creditImg" />
        <div class="caption">กด “ต่อไป” เพื่อยืนยันการชำระเงินผ่านช่องทางบัตรเครดิต</div>
        <b-button class="nextBtn" @click="userInfoFormData('credit')">ต่อไป</b-button>

        <form id="checkoutForm" class="checkout-form" name="checkoutForm" method="POST" action="https://api2.novelrealm.com/module/payment&action=payment_credit_charge">
            <input type="text" name="amount" style="display:none" :value="cash*100" />
            <input type="text" name="invoice" style="display:none" :value="invoice" />
            <input type="text" name="omiseToken" style="display:none" />
        </form>
    </div>

    <div v-else-if="paymentChannel == 'bank'">
        <img src="./../../../assets/scb.png" width="100px" />
        <div>
            บริษัทเกาอักษร:
            <span class="bankAccount">193-260227-5</span>
        </div>
        <img src="./../../../assets/allBank.png" width="150px" class="creditImg" />
        <div>
            <b-form-file v-model="file" placeholder="อัพโหลดหลักฐานการโอนเงิน" size="sm" browse-text="เลือกรูป" required></b-form-file>
            <!-- <div class="mt-1">Selected file: {{ file ? file.name : '' }}</div> -->

            <label for="name">ชื่อ-สกุล</label>
            <b-form-input id="name" v-model="name" placeholder="ชื่อ-สกุล (ลูกค้าเข้าของบัญชี)" style="marginBottom:10px"></b-form-input>
            <label for="name">วันที่และเวลาที่โอน</label>
            <datetime type="datetime" v-model="datetime" format="yyyy-MM-dd HH:mm:ss" :input-style="inputStyle" input-class="form-control" placeholder="วันที่และเวลาที่โอน (ตามสลิปหลักฐาน)"></datetime>
        </div>
        <b-button class="nextBtn" @click="userInfoFormData('bank')" :disabled="isSubmit">ยืนยันการชำระเงิน</b-button>
    </div>

    <div v-else-if="paymentChannel == 'linepay'">
        <img src="./../../../assets/linepay2.png" width="180px" class="creditImg" />
        <div class="caption">กด “ต่อไป” เพื่อยืนยันการชำระเงินผ่านช่องทาง Line Pay</div>
        <b-button class="nextBtn" @click="userInfoFormData('linepay')">ต่อไป</b-button>
    </div>

    <div v-else-if="paymentChannel == 'truewallet'">
        <img src="./../../../assets/truewallet.png" width="120px" class="creditImg" />
        <div style="marginTop:10%">
            <label for="truewalletNumber">เบอร์ True Wallet</label>
            <b-form-input id="truewalletNumber" v-model="truewalletNumber" placeholder="เบอร์ True Wallet ของคุณ"></b-form-input>
        </div>
        <div class="caption">กด “ต่อไป” เพื่อยืนยันการชำระเงินผ่านช่องทาง True Wallet</div>
        <b-button class="nextBtn" @click="userInfoFormData('truewallet')">ต่อไป</b-button>
    </div>

    <div v-else-if="paymentChannel == 'qrcode'">
        <b-modal v-model="showQrModal" :hide-header="true" :hide-footer="true">
            <div class="text-center">
            <img class="img-fluid" :src="qr"/>
            </div>
        </b-modal>
        <img src="./../../../assets/qrcode.png" width="120px" class="creditImg" />
        <b-button class="nextBtn" @click="userInfoFormData('qrcode')">ต่อไป</b-button>
    </div>

    <div v-else-if="paymentChannel == 'truemoney'">
        <img src="./../../../assets/truemoney.png" width="80px" class="creditImg" />
        <div>
            <label for="truemoneyNymber">รหัสบัตร Truemoney</label>
            <b-form-input id="truemoneyNymber" v-model="truemoneyNymber" placeholder="รหัสบัตร Truemoney"></b-form-input>
        </div>
        <div class="caption">กด “ต่อไป” เพื่อยืนยันการชำระเงินผ่านช่องทาง Truemoney</div>
        <b-button class="nextBtn">ต่อไป</b-button>
    </div>

    <div v-else-if="paymentChannel == 'shopee'">
        <img src="./../../../assets/shopeepay.png" width="120px" class="creditImg" />
        <b-button class="nextBtn" @click="userInfoFormData('shopee')">ต่อไป</b-button>
    </div>
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import axios from "axios";
import router from '../../../router';
import moment from 'moment'
import { notification } from "ant-design-vue";
export default {
    data() {
        return {
            userInfo: JSON.parse(localStorage.getItem("UserInfo")),
            name: "",
            truewalletNumber: "",
            truemoneyNymber: "",
            datetime: "",
            file: null,
            invoice: "",
            qr: "",
            showQrModal: false,
            checkGoldin: '',
            isSubmit: false
        };
    },
    computed: {
        ...mapGetters({
            paymentChannel: "GET_PAYMENT_CHANNEL",
            cash: "GET_CASH",
            history_path: "GET_HISTORY_PATH"
        })
    },
    props: {
        // paymentChannel: String,
        inputStyle: {
            type: [Object, Array, String],
            default: "border-radius: 10px;width: 100%;border: 1px solid #A8A8A8;height: 35px;fontSize:14px"
        }
    },
    methods: {
        async userInfoFormData(channel) {
            this.isSubmit = true;
            const formData = new FormData();
            formData.append("auth_memberid", this.userInfo.auth_memberid);
            formData.append("auth_reference", this.userInfo.auth_token);
            if (channel == "credit") {
                await this.paymentCredit(formData);
            } else if (channel == "bank") {
                await this.paymentBank(formData);
            } else if (channel == "linepay") {
                await this.paymentLinepay(formData);
            } else if (channel == "truewallet") {
                await this.paymentTruewallet(formData);
            } else if (channel == "qrcode") {
                await this.paymentQrcode(formData);
            } else if (channel == "shopee") {
                await this.paymentShopee(formData);
            }
            this.isSubmit = false;
        },
        async paymentCredit(formData) {
            formData.append("price", this.cash);

            try {
                await axios
                    .post(
                        "https://api2.novelrealm.com/module/payment&action=payment_credit",
                        formData
                    )
                    .then(res => {
                        //console.log(res.data);
                        if (res.data.status == 200) {
                            this.invoice = res.data.invoice
                            var form = document.querySelector("#checkoutForm");
                            window.OmiseCard.configure({
                                publicKey: 'pkey_5nlkgnrd1smr1i298s7',
                                amount: this.cash * 100,
                                image: require('@/assets/logo_novelrealm.png'),
                                frameDescription: "User: " + res.data.member_displayname + " <" + res.data.member_email + ">",
                                frameLabel: "Novelrealm's credit card system",
                                submitLabel: "ยืนยันการชำระเงิน",
                                onFormClosed: () => {
                                    //console.log('onFormClosed')
                                },
                                onCreateTokenSuccess: (nonce) => {
                                    //console.log('onCreateTokenSuccess : ', nonce)
                                    if (nonce.startsWith("tokn_")) {
                                        form.omiseToken.value = nonce;
                                        //console.log("form.omiseToken.value (200) : ", form.omiseToken.value)
                                    } else {
                                        form.omiseSource.value = nonce;
                                    }
                                    form.submit();
                                }
                            })
                            window.OmiseCard.open()
                        }
                    });
            } catch (err) {
                //console.log("FAILURE!!" + err);
            }
        },
        moment: function () {
            return moment();
        },
        async paymentBank(formData) {
            var fileConfirm = false
            formData.append("billing_price", this.cash);
            if (this.file != null) {
                formData.append("slip", this.file, "/path/to/file");
                fileConfirm = true
            } else {
                formData.append("billing_name", this.name);
                this.datetime = moment(new Date(this.datetime)).format('yyyy-MM-DD HH:mm:ss')
                formData.append("billing_datetime", this.datetime);
                if(this.datetime == "Invalid date" && this.name == ""){
                    alert("กรุณากรอกชื่อสกุล เวลาทำการโอน หรือ อัปโหลดสลิปทำรายการ")
                    fileConfirm = false
                }
                else{
                    fileConfirm = confirm("คำเตือน: คุณไม่ได้อัปโหลดสลิปต้องการทำรายการต่อหรือไม่");
                }
            }
            
            if (fileConfirm == true) {
                try {
                    await axios
                        .post(
                            "https://api2.novelrealm.com/module/payment&action=payment_bank",
                            formData
                        )
                        .then(res => {
                            //console.log(res.data);
                            if (res.data.status == 200) {
                                this.routeback();
                            }
                            // else if (res.data.status == 404) {

                            // }
                        });
                } catch (err) {
                    //console.log("FAILURE!!" + err);
                }
            }
        },
        async routeback(){
            this.backToFromPage()
        },
        async paymentLinepay(formData) {
            formData.append("billing_price", this.cash);
            try {
                await axios
                    .post(
                        "https://api2.novelrealm.com/module/payment&action=payment_linepay",
                        formData
                    )
                    .then(res => {
                        if (res.data.status == 200) {
                            window.location = res.data.redirect_url
                        }
                        // else if (res.data.status == 404) {

                        // }
                    });
            } catch (err) {
                //console.log("FAILURE!!" + err);
            }
        },
        async paymentTruewallet(formData) {
            formData.append("price", this.cash);
            formData.append("mobile", this.truewalletNumber);
            try {
                await axios
                    .post(
                        "https://api2.novelrealm.com/module/payment&action=payment_truewallet",
                        formData
                    )
                    .then(res => {
                        //console.log(res)
                        if (res.data.status == 200) {
                            window.location = res.data.description
                        }
                        else {
                            notification.open({
                                message: `Error ${res.data.status}`,
                                description: res.data.description
                            })
                        }
                    });
            } catch (err) {
                //console.log("FAILURE!!" + err);
            }
        },
        async workerupdateUser() {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            try {
                await axios.post('https://api2.novelrealm.com/module/profile&submodule=info', formData)
                    .then((res) => {
                        //console.log(res.data)
                        if (res.data.status == 200) {
                            if(res.data.member_gold != this.userInfo.member_gold){
                                router.push("/profile?menu=history")
                            }
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async paymentQrcode(formData) {
            formData.append("price", this.cash);
            try {
                await axios
                    .post(
                        "https://api2.novelrealm.com/module/payment&action=payment_qrcode",
                        formData
                    )
                    .then(res => {
                        if (res.status == 200) {
                            //console.log(res)
                            this.qr = res.data.qrcode_base64
                            this.showQrModal = true
                            this.checkGoldin = setInterval(this.workerupdateUser, 1000)
                        }
                    });
            } catch (err) {
                //console.log("FAILURE!!" + err);
            }
        },
        async paymentShopee(formData) {
            formData.append("price", this.cash);
            try {
                await axios
                    .post(
                        "https://api2.novelrealm.com/module/payment&action=payment_shopee",
                        formData
                    )
                    .then(res => {
                        if (res.data.status == "200"){
                            if(res.data.redirect_url !== null)
                                window.location.href = res.data.redirect_url
                            else{
                                this.$notification.open({
                                    message: 'ไม่สามารถเชื่อมต่อกับ ShopeePay ได้ กรุณาลองใหม่อีกครั้งในภายหลัง',
                                    icon: <a-icon type="disconnect" style="color: #f50" />,
                                });
                            }

                        }
                        else{
                            alert("ระบบไม่สามารถเชื่อมต่อกับ ShopeePay ได้ โปรดลองอีกครั้งภายหลัง")
                        }
                    });
            } catch (err) {
                this.$notification.open({
                    message: 'ไม่สามารถเชื่อมต่อกับ ShopeePay ได้ กรุณาลองใหม่อีกครั้งในภายหลัง',
                    icon: <a-icon type="disconnect" style="color: #f50" />,
                });
                // console.log("FAILURE!!" + err);
            }
        },
        async backToFromPage() {
            try {
                await router.push(this.history_path)
            }
            catch(err){
                router.push("/")
            }
        }
    },
    beforeDestroy(){
        clearInterval(this.checkGoldin)
    }
};
</script>

<style scoped>
.scope {
    text-align: center;
    margin: auto;
}

.creditImg {
    margin-top: 5%;
}

.caption {
    font-size: 14px;
    color: #adadad;
    position: absolute;
    width: 55%;
    bottom: 40%;
    left: 22%;
    margin: auto;
}

.nextBtn {
    font-size: 14px;
    font-weight: bold;
    background-color: #f5d087;
    color: white;
    border: none;
    border-radius: 10px;
    width: 80%;

    position: absolute;
    bottom: 10%;
    left: 10%;
}

.nextBtn:focus,
.nextBtn:hover,
.nextBtn:active {
    background-color: #fac762;
    box-shadow: none;
}

.bankAccount {
    color: #4b228d;
    font-weight: bold;
    font-style: italic;
}

label {
    float: left;
    font-size: 12px;
    margin-bottom: 0px;
}

input,
.upload {
    border-color: #a8a8a8;
    font-size: 14px;
    border-radius: 10px;
}
@media screen and (max-width: 500px) {

    .nextBtn,
    .caption {
        position: static;
        margin-top: 15%;
    }

    .caption {
        width: 80%;
    }
}
</style>
