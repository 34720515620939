<template>
<div class="scope">
    <b-modal v-model="showLoginModal" :hide-header="true" :hide-footer="true" :no-close-on-backdrop="true">
        <Login />
    </b-modal>
    <PaymentBtn />
    <!-- <div class="text-center" v-if="isLoadData">
        <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
    </div> -->
    <b-row v-if="isSelected == true" class="mt-2">
        <b-col sm="8" id="paymentInfoLeft" class="paymentInfo paymentInfoLeft">
            <PaymentSelectCoin />
        </b-col>
        <b-col sm="4" id="paymentInfoRight" class="paymentInfo paymentInfoRight" style="padding: 3%;">
            <PaymentChannel />
        </b-col>
    </b-row>
</div>
</template>

<script>
import PaymentBtn from './../elements/addcoin/PaymentBtn.vue'
import PaymentSelectCoin from './../elements/addcoin/PaymentSelectCoin.vue'
import PaymentChannel from './../elements/addcoin/PaymentChannel.vue'
import Login from "./../pages/Login"
import {
    mapGetters
} from 'vuex'
export default {
    name: "Homepage",
    components: {
        Login,
        PaymentBtn,
        PaymentSelectCoin,
        PaymentChannel
    },
    mounted() {
        //document.title = 'เติมทอง';
        this.login();
        this.checkMode();
    },
    watch: {
        mode() {
            this.checkMode()
        }
    },
    data() {
        return {
            paymentInfo: "",
            paymentChannel: "",
            imgCredit: "credit-card",
            imgBank: "bank",
            imgLinepay: "linepay",
            imgPay: "pay",
            imgTruewallet: "wallet",
            imgTruemoney: "wallet",
            channel: ["credit", "bank", "linepay", "truewallet", "truemoney"],
            islogin: localStorage.getItem('UserInfo') != null
        }
    },
    metaInfo() {
        return { 
            title: "เติมทอง | NovelRealm ศูนย์รวมนิยายแปล นิยายแต่ง ขึ้นหิ้งมากมาย",
            meta: [
                { name: 'description', content:  'NovelRealm นิยายแปลที่ถูกคัดสรรมาอย่างดี การันตีด้วยยอดผู้อ่านกว่าล้านครั้ง ทั้งนิยายแฟนตาซี นิยายกำลังภายใน นิยายรักโรแมนติก'},
                { property: 'og:title', content: "เติมทอง | NovelRealm ศูนย์รวมนิยายแปล นิยายแต่ง ขึ้นหิ้งมากมาย"}
            ]
        }
    },
    computed: {
        ...mapGetters({
            isSelected: 'GET_IS_SELECTED',
            mode: 'GET_MODE'
        })
    },
    methods: {
        login(){
            if(this.islogin == false){
                this.showLoginModal = true
            }
        },
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('paymentInfoLeft').classList.remove('darkmode')
            document.getElementById('paymentInfoRight').classList.remove('darkmode')
        },
        darkmode() {
            document.getElementById('paymentInfoLeft').classList.add('darkmode')
            document.getElementById('paymentInfoRight').classList.add('darkmode')
        }
    }
}
</script>

<style scoped>
.scope {
    padding: 1% 10% 1% 10%;
}

.pageTopic {
    font-size: 20px;
    font-weight: bold;
}

.paymentChannel {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 8%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

.paymentChannelActive {
    color: #EE806B;
}

.allChanel,
.channel {
    padding: 0px;
}

.paymentInfo {
    background-color: #ffffff;
}

.paymentInfoLeft {
    border: 20px solid #D4E6ED;
    border-top-width: 20px;
    border-bottom-width: 20px;
    border-Left-width: 20px;
    border-Right-width: 0px;
}

.paymentInfoRight {
    border: 20px solid #D4E6ED;
    border-top-width: 20px;
    border-bottom-width: 20px;
    border-Left-width: 10px;
    border-Right-width: 20px;
}

.darkmode {
    background-color: #35363A;
    border: 20px solid #000000;
}

.darkmode .paymentInfo, .paymentInfoLeft, .paymentInfoRight {
    color: #000;
}


@media screen and (max-width: 500px) {

    .scope {
        padding: 2% 2% 2% 2%;
    }

    .pageTopic {
        font-size: 14px;
        text-align: center;
    }

    .paymentChannel {
        font-size: 14px;
        width: 85%;
    }

    .true {
        margin-top: 10%;
        margin-left: 57%;
    }

    .paymentInfo {
        margin-top: 5%;
    }

    .paymentInfoLeft,
    .paymentInfoRight {
        border: none
    }
}

@media screen and (min-width: 1441px) {
    .scope {
        padding: 1% 20% 1% 20%;
    }
}
</style>
